/* Container styling */
.our-work-section {
  padding: 40px 0;
}

/* .container {
  max-width: 1200px;
  margin: 0 auto;
} */

/* .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

/* First Row Styling */
.first-row {
  margin-bottom: 20px;
}

.first-col {
  flex: 2;
}

.subheading {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
}

.main-heading {
  
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0;
  color: #333;
}

.second-col {
  flex: 1;
  text-align: right;
}

/* Swiper navigation */
.swiper-navigation {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.swiper-button-prev,
.swiper-button-next {
  cursor: pointer;
  font-size: 2rem;
  color: #000;
}

/* Second Row (Swiper) */
.second-row {
  width: 100%;
}

/* Card Styling */
.our-work-card {
  
  border: 1px solid #dddddd;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  /* text-align: center; */
  padding: 0px 0px 20px 0px;
}

.card-link {
  text-decoration: none;
}

.our-work-img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}

.category-name {
  font-size: 1rem;
  color: #333333c4;
  font-weight: bold;
  border: 1px solid #5b3aef63;
  border-radius: 10px;
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #555;
  padding-left: 25px;
}

.swiper-button-prev, .swiper-button-next {
  display: inline-block;
  color: #000; /* Adjust arrow color */
  font-size: 20px;
  margin: 0 10px;
  cursor: pointer;
}

.swiper-button-prev::after, .swiper-button-next::after {
  font-size: 24px; /* Adjust arrow size */
}



.our-work-card {
  position: relative; /* Ensure positioning for the sliding effect */
  overflow: hidden; /* Hide overflowing content */
  transition: transform 0.3s ease; /* Smooth transition for the transform property */
}

.our-work-card:hover {
  transform: translateY(-10px); /* Move the card up by 10 pixels on hover */
}
