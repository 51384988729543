body {
    /* font-family: 'Poppins', sans-serif; */
    margin: 0;
    padding: 0;
}

.btn-custom {
    background-color: #3E1DCE !important;
    color: #ffffff !important;
}


/* black */

button.btn.orange-button {
    color: #fff;
    background: #6A48FF;
}

button.btn.orange-button:hover {
    color: #fff;
    background: #6A48FF;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    display: none;
    pointer-events: none;
}



/* Background for the banner */
.black-friday-banner {
    background-color: #0a0d16;
    background-image: url('images/BlackFriday-101.jpg'), radial-gradient(circle, #0a0d16 10%, transparent 10%), radial-gradient(circle, #0a0d16 10%, transparent 10%);
    background-size: cover, 60px 60px, 60px 60px;
    background-position: center, 0 0, 30px 30px;
    padding: 50px 0;
    position: relative;
    color: #fff;
    text-align: center;
    overflow: hidden; /* Ensure no content overflows during animations */
    animation: fadeIn 2s ease-out; /* Fade-in effect for the banner */
}

/* Fade-in animation for the entire banner */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Confetti animation */
@keyframes confettiAnimation {
    0% {
        transform: translateY(0) rotate(0);
        opacity: 0;
    }
    50% {
        opacity: 1;
        transform: translateY(-100px) rotate(180deg);
    }
    100% {
        transform: translateY(0) rotate(360deg);
        opacity: 0;
    }
}

/* Colorful confetti-like shapes */
.black-friday-banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-image: url('path/to/confetti.png'); Replace with your confetti image */
    animation: confettiAnimation 3s infinite; /* Confetti animation */
    opacity: 0.5;
    pointer-events: none;
}

/* Text and Button Styling */
.black-friday-banner h6 {
    font-size: 1rem;
    letter-spacing: 3px;
    color: #bbbbbb;
    margin-bottom: 10px;
    animation: fadeInText 2s ease-out; /* Animation for subtitle text */
}

/* Animation for the header text */
@keyframes fadeInText {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.black-friday-banner h1 {
    font-size: 6rem;
    font-weight: 800;
    letter-spacing: 2px;
    color: #ffffff;
    margin-bottom: 20px;
    animation: slideInFromLeft 2s ease-out; /* Slide-in effect for title */
}

/* Slide-in effect for the main title */
@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.black-friday-banner-explore-btn {
    background-color: #5e33f2;
    color: #ffffff;
    padding: 10px 20px;
    font-weight: bold;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    animation: fadeInButton 2s ease-out 1s; /* Button fade-in with delay */
}

/* Fade-in effect for the button */
@keyframes fadeInButton {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.black-friday-banner-explore-btn:hover {
    background-color: #3e23d2;
    color: #ffffff;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
    .black-friday-banner {
        padding: 60px 0;
    }

    .black-friday-banner h1 {
        font-size: 3.5rem;
    }

    .black-friday-banner h6 {
        font-size: 0.9rem;
        letter-spacing: 2px;
    }

    .black-friday-banner-explore-btn {
        padding: 8px 16px;
        font-size: 1rem;
    }
}

@media (max-width: 576px) {
    .black-friday-banner {
        padding: 40px 0;
    }

    .black-friday-banner h1 {
        font-size: 2.5rem;
    }

    .black-friday-banner h6 {
        font-size: 0.8rem;
        letter-spacing: 1px;
    }

    .black-friday-banner-explore-btn {
        padding: 6px 12px;
        font-size: 0.9rem;
    }
}












/* Start header */
/* Start header */

.custom-header .navbar {
    background:  #160a48;
    padding: 20px 0;
    transition: background-color 0.3s ease;
}

.custom-header .navbar-brand img {
    max-width: 222px;
    width: 502px;
}

.custom-header .custom-nav-link {
    color: #ffffff !important;
    /* White text for navigation links */
    font-weight: 600;
    margin-right: 15px;
}

.custom-header .custom-nav-link:hover {
    color: #dbe1ff !important;
    /* Hover effect */
}

.custom-header .custom-phone-number a {
    color: #ffffff !important;
    /* White color for the phone number */
    font-weight: 600;
}

.custom-header .custom-phone-number a:hover {
    color: #dbe1ff !important;
    /* Hover effect for phone number */
}

.custom-header .navbar.fixed-top.scrolled {
    background: #00000075;
}

/* Scrollbar track (background of the scrollbar) */
::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
    height: 8px; /* Set the height for horizontal scrollbar */
}

/* Scrollbar thumb (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
    background-color: #6A48FF; /* Set the color */
    border-radius: 10px; /* Rounded corners for the thumb */
    border: 2px solid #6A48FF; /* Optional: adding border for more contrast */
}

/* Scrollbar track when hovered */
::-webkit-scrollbar:hover {
    width: 10px; /* Optional: Increase width when hovered */
}

/* Scrollbar thumb when hovered */
::-webkit-scrollbar-thumb:hover {
    background-color: #5e3de7; /* Slightly darker shade on hover */
}
/* End header */

h1.thankyou {
    font-size: 3rem;
    font-weight: 800;
    text-transform: uppercase;
}


.navbar-toggler {
    padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    color: rgb(0 0 0);
    background-color: #ffffffc2;
    border: var(--bs-border-width) solid rgb(255 255 255);
    border-radius: var(--bs-navbar-toggler-border-radius);
    transition: var(--bs-navbar-toggler-transition);
}


/* Footer Styles */
.footer {
    background-color: #3E1DCE;
    /* Footer background color */
    padding-top: 40px;
    padding-bottom: 0px;
    color: white;
}

.footer .footer-heading {
    font-weight: bold;
   
    margin-bottom: 10px;

}

.footer p {
    font-size: 14px;
    line-height: 1.5;
    padding-right: 50px;
}

.footer .footer-links {
    padding-left: 0;
    /* Remove padding for the links */
}

.footer .footer-links li {
    list-style: none;
    text-align: left;
    /* Align list items to the left */
}

.footer .footer-links a {
    color: #ffffff;
    /* Set link color to white */
    text-decoration: none;
    font-size: 14px;
}

.footer .footer-links a:hover {
    color: #dbe1ff;
}

.footer .social-icons a {
    color: white;
    margin-right: 10px;
}

.footer .payment-icons img {
    max-width: 160px;
    margin-right: 10px;
}

.footer-bottom {
    border-top: 1px solid #2b0f91;
    padding-top: 15px;
    font-size: 14px;
}

.footer-bottom a {
    color: #ffffff;
    text-decoration: none;
    margin-right: 10px;
}

.footer-bottom a:hover {
    color: #dbe1ff;
}
/* End Footer Styles */


/* Banner Section */

.secbanner-section{
    display: flex;
    align-items: center;
    height: 85vh;
    position: relative;
    color: white;
    overflow: hidden;
}
.secbanner-section img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    border-radius: 0;
    /* Removed border radius */
}
    
.banner-section {
    display: flex;
    align-items: center;
    height: 85vh;
    position: relative;
    color: white;
    overflow: hidden;
}

.banner-section img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    border-radius: 0;
    /* Removed border radius */
}

.banner-content {
    text-align: left;
}

.banner-content h1 {
    font-size: 50px;
    font-weight: 800;
    line-height: 1.2em;
    margin-bottom: 10px;
}

.banner-content p {
    margin: 20px 0;
    color: rgb(168, 168, 168);
    font-size: 1.2rem;
    margin-bottom: 50px;
}

.cta-button {
    padding: 12px 24px;
    background-color: #5e33f2;
    color: white;
    font-size: 1.1rem;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background-color: #3e23d2;
}

.wrapper {
    max-width: 1400px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
}

@media (max-width: 576px) {
    .banner-content h1 {
        font-size: 2.5rem;
    }

    .banner-content p {
        font-size: 1rem;
    }

    .cta-button {
        font-size: 1rem;
        padding: 10px 20px;
    }
}

/* Removed border-radius from images */
.banner-images {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
}

img.icon-image {
    position: unset;
    width: 120px;
}
.banner-image {
    width: 120px;
    height: auto;
}

@media (max-width: 768px) {
    .banner-image {
        width: 90px;
    }
}

/* Logo Section */
.logo-section {
    background-color: #4A20E0;
    padding: 40px 0;
    text-align: center;
}

.logo-section img {
    max-width: 150px;
    padding: 10px 20px;
}

.logo-wrapper {
    text-align: center;
}

.icon-image-ic {
    width: 70px;
    height: 70px;
    /* margin-right: 30px; */
    /* margin-top: -90px; */
}
/* Form Section */

.get-started-section {
    background-color: #f9f9f9;
    padding: 70px 0px;
}

.card {
    background-color: #f3f3f3;
    border-radius: 15px;
    border: 3px solid #d6cfff;
}

.card h2 {
    color: #2a2a2a;
    font-size: 28px;
}

.card p {
    color: #606060;
    font-size: 16px;
}

/* .form-control {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    height: 50px;
    font-size: 16px;
    border-radius: 8px;
} */

/* .btn-primary {
    color: #fff;
    background-color: #5e33f2;
    border-color: #5e33f2;
    padding: 10px 40px;
    border-radius: 8px;
    font-size: 18px;
}

.btn-primary:hover {
    background-color: #4a20e0;
    border-color: #4a20e0;
} */

.my-abouts {
    padding: 40px 0;
}

.sub-heading {
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 15px;
    color: #A5A5A5;
}

.about-heading {
    font-weight: 700;
    font-size: 40px;
    color: #000000;
    margin-bottom: 30px;
}

.about-desc {
    font-size: 18px;
    margin-bottom: 50px;
    margin-top: 35px;
    margin-left: 10px;
    color: #666666;
    font-weight: 400;
    text-align: justify;
}

.icon-box {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.icon-image {
    width: 60px;
    height: 60px;
    margin-right: 30px;
    margin-top: -50px;
}

.icon-content h4 {
    font-size: 28px;
    margin-bottom: 5px;
    font-weight: 600;
}

.icon-content p {
    margin: 0;
    font-size: 14px;
    text-align: justify;
    color: #666666;

}



/* ------------START DOC QA--------------*/

@media only screen and (min-width: 768px) and (max-width: 1081px) and (orientation: portrait) {
    .col-md-7 {
        flex: 0 0 auto;
        width: 99%;
    }

    .col-md-5 {
        flex: 0 0 auto;
        width: 99%;
    }

    .about-heading {
        margin-bottom: 0px; 
    }
    .about-desc {
        margin-top: 18px;
        margin-left: 0px;
        margin-bottom: 20px; 
    }
    .my-abouts {
        padding-bottom: 0px;
    }
    .custom-carousel-control {
        margin-right: 8px;
    }
    .swiper-button-prev, .swiper-button-next{
        display: none;
    }
    .unique-ui-designs-section{
        padding: 0px !important;
    }
    .unique-ui-designs-section h3 {
        font-weight: 700 !important;
        font-size: 22px !important;
    }
    .footer .col-md-4 {
        flex: 0 0 auto;
        width: 100%;
    }
    .footer .col-md-2 {
        flex: 0 0 auto;
        width: 50%;
    }
    .about-heading {
        font-weight: 700;
        font-size: 30px;
        color: #000000;
        margin-bottom: 30px;
    }
    .d-md-block {
        display: none !important;
    }
    .unique-ui-designs-section h2 {
        font-weight: 700;
        font-size: 38px !important;
        color: #000000;
    }

}
/* for mobile */

@media screen and (min-width: 360px) and (max-width: 767px) {

    .banner-section {
        height: 68vh;
    }
    .banner-content {
        padding: 0px;
    }
    .banner-content h1 {
        font-size: 32px;
    }
    .about-heading {
        font-size: 22px;
        margin-bottom: 0px;
    }
    .about-desc {
        font-size: 14px;
        margin-bottom: 20px;
        margin-top: 11px;
        margin-left: 0px;
        color: #666666;
        font-weight: 400;
        text-align: justify;
    }
    img.icon-image {
        position: unset;
        width: 60px;
    }
    .icon-box {
        align-items: center;
        display: block;
        margin-top: 30px;
        margin-bottom: 50px;
    }
    .my-abouts {
        padding: 0px;
    }
    .main-heading {
        font-size: 27px !important;
        margin-bottom: 10px !important;
    }
    .custom-carousel-control {
        margin-right: 10px !important;
    }
    .pricing-heading-one {
        font-weight: 700;
        font-size: 32px !important;
        margin-bottom: 0px !important;
    }
    .our-work-section{
        padding-top: 40px;
        padding-bottom: 0px !important;
    }
    .unique-number {
        font-size: 14px !important;
        font-weight: bold;
        color: #999;
    }
    .unique-number {
        font-size: 14px !important;
        font-weight: bold;
        color: #999;
    }
    .unique-ui-designs-section h3 {
        font-weight: 700 !important;
        font-size: 14px !important;
    }
    .unique-ui-designs-section p {
        margin: 0;
        font-size: 12px;
        text-align: center;
    }
    .unique-ui-designs-section{
        padding: 0px !important;
    }
    
    .portfolio-heading {
        font-weight: 700;
        font-size: 32px !important;
        text-align: center;
    }
    .custom-text-section-v3 h2 {
        font-size: 24px !important;
    }
    .custom-logo-process h2 {
        font-size: 30px !important;
    }
    .custom-logo-process p {
        font-size: 16px !important;
    }
    .custom-content-v3 h2 {
        font-size: 24px !important;
    }
    .unique-ui-designs-section h2 {
        font-weight: 700;
        font-size: 30px !important;
        color: #000000;
    }


}
/* ------------END DOC QA--------------*/







/* Privacy policy page css */
.simple-section{
    margin-top: 100px;
    margin-bottom: 50px;
}

@media (max-width: 768px) {
    .col-lg-3 {
        width: 100%;
    }

    .get-started-section {
        padding: 30px 10px;
    }
}

/* Custom carousel section */
.custom-carousel-section {
    padding: 40px 0;
    background-color: #f9f9f9;
}

/* Custom container */
.custom-carousel-container {
    max-width: 1200px;
    margin: 0 auto;
}

/* Title */
.custom-carousel-title {
    font-size: 2rem;
    font-weight: bold;
}

/* Carousel controls styling */
.custom-carousel-control {
    background-color: #000;
    padding: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

/* Custom carousel slide */
.custom-carousel-slide {
    position: relative;
}

.custom-carousel-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* Custom card styles */
.custom-carousel-card {
    border: none;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease;
    background-color: #fff;
    height: 100%;
}

.custom-carousel-card:hover {
    transform: translateY(-10px);
}

/* Image styles */
.custom-carousel-img {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: 250px;
    object-fit: cover;
    width: 100%;
}

/* Card body and text */
.custom-carousel-card-body {
    padding: 20px;
}

.custom-carousel-card-title {
    display: inline-block;
    padding: 5px 5px;
    border: 1px solid #5C3AEF;
    color: #3f3f3fbf;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
}

.custom-carousel-card-title:hover {
    background-color: rgba(128, 0, 128, 0.1);
    /* Optional hover effect */
}


.custom-carousel-card-text {
    font-size: 24px !important;
    color: #000000 !important;
    font-weight: 600;
}

/* Responsive behavior */
@media (max-width: 767.98px) {
    .custom-carousel-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .custom-carousel-inner {
        display: flex;
        flex-wrap: wrap;
    }

    .custom-carousel-card {
        margin: 15px;
    }
}

.custom-carousel-slide {
    overflow: hidden;
}

.custom-carousel-control {
    background-color: #000;
    padding: 10px;
    border-radius: 50%;
}

.custom-carousel-img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.custom-carousel-card-body {
    text-align: center;
}




.unique-ui-designs-section {
    padding: 50px 0px;
}

.unique-ui-designs-section h6 {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    color: #999;
}

.unique-ui-designs-section h2 {
    font-weight: 700;
    font-size: 54px;
    color: #000000;
}

/* .unique-ui-designs-section h3 {
    font-weight: 500;
    font-size: 32px;
} */

.unique-number {
    font-size: 20px;
    font-weight: bold;
    color: #999;
}

/* .unique-ui-designs-section p {
    margin: 0;
    font-size: 18px;
    text-align: center;
} */

.section-divider {
    border: none;
    border-top: 1px solid #666666;
    /* Light grey color */
    margin: 20px 0;
    /* Adds space around the divider */
}




.unique-image-gallery {
    padding: 20px 0;
    /* Add padding around the gallery */
}

.unique-gallery-image {
    cursor: pointer;
    /* Show pointer on hover */
    transition: transform 0.2s;
    /* Animation effect */
}

.unique-gallery-image:hover {
    transform: scale(1.05);
    /* Slight zoom effect on hover */
}

.unique-lightbox-container {
    display: none;
    /* Initially hide the lightbox */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure it's above other content */
}

.unique-lightbox {
    position: relative;
    max-width: 80%;
    max-height: 80%;
}

.unique-lightbox-title {
    color: white;
    /* Lightbox title color */
    margin-bottom: 10px;
    /* Space below title */
}

/* Add styles for tabs */
.nav-pills .nav-link {
    margin: 0 10px;
    border-radius: 20px;
    /* Rounded tabs */
}

.nav-pills .nav-link.active {
    background-color: #646bd9;
    /* Active tab color */
    color: white;
    /* Active tab text color */
}

.gallery-section {
    padding: 60px 0;
    background-color: #f8f9fa;
}

.gallery-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 40px;
}

#portfolio {
    margin: 1rem 0;
    column-count: 5;
    /* Change to 5 columns */
    column-gap: 1rem;
}

.tile {
    margin-bottom: 1rem;
    display: inline-block;
    /* Ensure tiles are displayed inline */
}

.scale-anm {
    border: 1px solid #ccc;
}

.tile img {
    width: 100%;
    height: auto;
    display: block;
}

/* .btn {
    font-family: Lato, sans-serif;
    font-size: 1rem;
    padding: .5rem 1rem;
    border: 1px solid #ccc;
    color: #555;
    background-color: transparent;
} */

.toolbar {
    text-align: center;
    margin-bottom: 30px;
}

/* .toolbar .btn {
    margin: 0 5px;
} */

/* Responsive for mobile */
@media (max-width: 768px) {
    #portfolio {
        column-count: 3;
        /* Reduce to 3 columns on medium screens */
    }
}

@media (max-width: 576px) {
    #portfolio {
        column-count: 1;
        /* Reduce to 1 column on small screens */
    }
}

/* ENGAGING UI SECTION */
.engaging-ui-section .btn-custom {
    width: 182px;
    height: 48px;
}


/* ENGAGING UI SECTION */

/****************************************************/

/* ENGAGING UI CAROUSEL SECTION */
.engaging-ui-carousel-section .carousel-action-btns button {
    background-color: white;
    border: none;
    font-size: 35px;
}

/****************************************************/

/* APP DEVELOPMENT PROCESS SECTION */

.app-dev-process-section
.about-heading {
    /* width: 560px; */
    margin: auto;
}

.steps-heading {
    color: #3E1DCE;
}

.step-2 {
    margin-top: 350px;
}

.step-3 {
    margin-top: 390px;
}

.step-4 {
    margin-top: 390px;
}

/* APP DEVELOPMENT PROCESS SECTION */

/****************************************************/

/* TECHNOLOGY STACK SECTION */

.tech-stack-section
.description {
    font-size: 18px;
    color: #666666;
    width: 50%;
    margin: auto;
}

/* TECHNOLOGY STACK SECTION */
