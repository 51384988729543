.icon-textbox-section .subheading {
  font-size: 14px;
  color: #999;
}
.icon-textbox-section .section-heading {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}
.iconbox2-row {
  display: flex;
  justify-content: space-around;
}
.iconbox2 {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #F9F9F9;
  transition: all 0.3s ease;
}
.iconbox2:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.iconbox2-icon {
  font-size: 32px;
  margin-right: 15px;
  color: #6C63FF;
}
.iconbox2-text-content h3 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
}
.iconbox2-text-content p {
  color: #666;
  font-size: 14px;
}






