.testimonial-section {
    /* background-color: #f9f9f9; */
    padding: 40px 0px 0px 0px;
  }
  .testimonial-section h2{
    font-size: 48px;
    font-weight: 800;
    text-align: center;
    margin-top: 20px;
    color: #333;
}
  .testimonial-card {
    background-color: #ffffff; /* Card color white */
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* text-align: center; */
  }
  
  .stars svg {
    margin-right: 5px;
  }
  
  .swiper-pagination-bullets {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background: #000;
    opacity: 0.5;
    transition: opacity 0.3s;
  }
  .swiper {
    padding: 2px 0px;
}
  .swiper-pagination-bullet-active {
    opacity: 1;
  }
  
  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    display: none;
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
}
.testimonial-image {
  width: 50px; /* Adjust size */
  height: 50px; /* Adjust size */
  object-fit: cover; /* Ensure the image covers the container */
}
.testimonial-heading {
  font-size: 1.2rem;  /* Adjust size as needed */
  font-weight: 700;   /* Bold */
  color: #010935;     /* Dark color for the heading */
  margin-bottom: 15px;
  text-align: left;   /* Left align the heading */
}


@media (max-width: 767px) {

.testimonial-section h2 {
  font-size: 26px;  /* Adjust size as needed */

}
}
