.all-in-one-combo-section {
    background: url('../../images/all-in-one-banner-bg.png');
    color: white;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

.all-in-one-combo-section .sub-title {
    font-size: 20px;
}

.all-in-one-combo-section .title {
    font-size: 51px;
    font-weight: 700;
}

.all-in-one-combo-section .banner-img {
    width: 100%;
    height: 460px;
}

.all-in-one-combo-section .order-btn {
    background-color: white !important;
    color: #e9623e !important;
    border: 1px salmon;
}

.all-in-one-combo-section .order-btn:hover {
    background-color: #e9623e !important;
    border: 1px solid white !important;
    color: white !important;
}

.all-in-one-combo-section .call-btn {
    background-color: #e9623e !important;
    border: 1px solid white !important;
    color: white !important;
}

.all-in-one-combo-section .call-btn:hover {
    background-color: white !important;
    color: #e9623e !important;
}

/* For screen 1400px & up */
@media (max-width: 1571.98px) {
    .all-in-one-combo-section .banner-img {
        height: 460px;
        margin: auto;
        display: block;
    }
}

/* For screen 1200px to 1399px */
@media (max-width: 1399.98px) {
    .all-in-one-combo-section .banner-img {
        height: 430px;
    }
}

/* For screen 992px to 1199px */
@media (max-width: 1199.98px) {
    .all-in-one-combo-section .banner-img {
        height: 600px;
    }
}

/* For screen 768px to 991px */
@media (max-width: 991.98px) {
    .all-in-one-combo-section .banner-img {
        height: 520px;
    }
}

/* For screen 576px to 767px */
@media (max-width: 767.98px) {
    .all-in-one-combo-section .banner-img {
        height: 390px;
    }
}

/* For screen 480px to 575px */
@media (max-width: 575.98px) {
    .all-in-one-combo-section .banner-img {
        height: 350px;
    }
}

/* For screen 350px to 479px */
@media (max-width: 479.98px) {
    .all-in-one-combo-section .banner-img {
        height: 250px;
    }
}
