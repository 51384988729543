.blogs-page .hero-section h1 {
    font-size: 32px;
}

.blogs-page .hero-section .blog-container {
    box-shadow: 0 3.84px 31.18px 10px rgba(0, 0, 0, .05);
    border-radius: 20px;
    padding: 16px 22px;
}

.blogs-page .hero-section .blog-container a {
    text-decoration: none !important;
}

.blogs-page .hero-section .blog-container img {
    border-radius: 20px;
}

.blogs-page .latest-article-section {
    background-color: #cae9ff;;
}

.blogs-page .latest-article-section .article-container {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    height: 100%;
}

.blogs-page .latest-article-section .article-container a {
    text-decoration: none;
}

.blogs-page .latest-article-section .article-container img {
    border-radius: 10px;
    /* height: 200px; */
}

.blogs-page .latest-article-section .custom-btn-primary:hover {
    background-color: #cae9ff;;
}


/* Gray font color */
.gray-font {
    color: #808080; /* Light gray color */
}

/* Font weight light */
.fw-light {
    font-weight: 300; /* Lighter font weight */
}

/* Font weight semibold */
.fw-semibold {
    font-weight: 600; /* Semibold font weight */
}

/* Uppercase text */
.text-uppercase {
    text-transform: uppercase; /* Converts text to uppercase */
}

/* Hero Section Styling */
.hero-section {
    background-color: #f8f9fa; /* Light background color for hero section */
    padding-top: 3rem;
    padding-bottom: 3rem;
}

/* Blog container styling */


/* Latest Article Section */
.latest-article-section {
    background-color: #ffffff;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.article-container {
    border: 1px solid #e0e0e0;
    padding: 15px;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.article-container:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.article-container img {
    max-height: 200px;
    object-fit: cover;
    width: 100%;
    border-radius: 5px;
}

/* Custom Button */
.custom-btn-primary {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
}

.custom-btn-primary:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.custom-btn-primary:hover {
    background-color: #0056b3;
}


.bg-green {
    background-color: #5331E5; /* Bootstrap's "success" green color */
  }
/* Custom CSS */
.badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
  }
  
  .rounded-pill {
    border-radius: 50rem !important;
  }
  
  .bg-green {
    background-color: #5331E5; /* Green color */
    color: white; /* Text color */
  }
  
  .px-3 { padding-left: 1rem; padding-right: 1rem; }
  .py-2 { padding-top: 0.5rem; padding-bottom: 0.5rem; }

/* For screen 768px to 991px */
@media (max-width: 991.98px) {
    .blogs-page .hero-section .blog-container-2,
    .blogs-page .hero-section .blog-container-3 {
        height: auto !important;
    }
}
