/* pricing */

.package-list {
    list-style-type: none; /* Remove default bullets */
    padding-left: 0; /* Remove padding */
  }
  
  .package-list li {
    display: flex; /* Use flexbox to align items horizontally */
    align-items: center; /* Vertically center the checkmark with the text */
    margin-bottom: 10px; /* Space between list items */
  }
  
  .check-icon {
    color: #3E1EC9; /* Set the color of the checkmark to orange */
    margin-right: 10px; /* Add space between the checkmark and the text */
    font-size: 18px; /* Set the size of the checkmark */
  }
  
  .scrollable-content {
    max-height: 250px;
    overflow-y: auto;
    margin: 15px 0;
    font-size: 12px;
    color: #000000;
    font-weight: 600;
}

.pricing-heading-one {
    font-weight: 700;
    font-size: 54px;
}

.pricing-one {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 15px;
    color: #A5A5A5;

}

.package-list {
    list-style-type: none;
    padding: 0;
    text-align: left;
}

.package-list li {
    margin: 10px 0;
}

.package-list {
    list-style-type: none; /* Remove default bullets */
    padding-left: 0; /* Remove padding */
  }
  
  .package-list li {
    display: flex; /* Use flexbox to align items horizontally */
    align-items: center; /* Vertically center the checkmark with the text */
    margin-bottom: 10px; /* Space between list items */
  }
  

  

.package-header {
    margin-bottom: 15px;
    text-align: left;

}

.package-header h5 {
    text-align: left;
    color: #3E1EC9;
    font-weight: 500;
}


.package-btn {
    display: inline-block;
    padding: 10px 80px;
    background: #333333;
    color: #fff;
    border: none;
    border-radius: 25px;
    text-decoration: none;
    margin-top: 15px;
}

.package-btn:hover {
    background-color: #3E1EC9;
}

h2.price-heading.mb-4 {
    font-size: 38px;
    font-weight: 700;
    /* text-transform: capitalize; */
    /* text-transform: uppercase; */
    color: #333333;
}

.price {
    display: flex;
    text-align: left;
    justify-content: left;
}

.price h2 {
    margin-right: 10px;
    color: rgb(0, 0, 0);
    font-weight: 700;
}

.price strike {
    color: gray;
}

.scrollable-content::-webkit-scrollbar {
    width: 3px;
}

.scrollable-content::-webkit-scrollbar-thumb {
    background-color: #3E1EC9;
    border-radius: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
    background-color: #B4B3BD;
}

.package {
    position: relative;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    overflow: hidden;
    /* Ensures the gradient doesn't overflow */
    z-index: 1;
}

.package::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    /* Matches the main element’s border radius */
    padding: 1px;
    /* Matches the border width */
    background: linear-gradient(0deg, rgba(35, 2, 177, 0.1) 2.39%, #853101 36.71%);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: -1;
    /* Places gradient behind content */
}

/* PricingSection.css */
#pricing-tabs .nav-item {
    margin-right: 10px;
    /* Adjust spacing as needed */
    margin-top: 10px;
}

#pricing-tabs .nav-item:last-child {
    margin-right: 0;
    /* Removes extra spacing on the last item */
}

.most-popular {
    background-color: #853101;
    color: white;
    padding: 7px 9px;
    font-weight: 600;
}
