/* .contactuspg-section {
  background-color: #f8f9fa;
} */

.contactuspg-bg{

background-color: #f8f9fa;
}

h2.contactuspg-heading-row {
  font-size: 2em;
  font-weight: 400px;
}
textarea.form-control.contactuspg-input {
  height: 200px !important;
}

.contactuspg-heading-row .contactuspg-heading {
  font-size: 2rem;
  font-weight: bold;
}

.contactuspg-heading-row .contactuspg-subtext {
  font-size: 1.1rem;
  color: #666;
}


.contactuspg-info-box {
  padding: 50px 20px 100px 50px !important;
  background-color: #673ab7;
  color: white;
  border-radius: 10px;
  width: 400px;
  place-self: center;
}

.contactuspg-info-box h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.contactuspg-info-box p {
  font-size: 1rem;
  margin-bottom: 20px;
}

.contactuspg-info-box address {
  line-height: 1.8;
}

.contactuspg-link {
  color: white;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 10px;
}

.contactuspg-link:hover {
  text-decoration: underline;
}

.contactuspg-social-icons {
  margin-top: 20px;
}

.contactuspg-social-icons a {
  color: white;
  font-size: 1.5rem;
  margin-right: 15px;
  transition: 0.3s;
}

.contactuspg-social-icons a:hover {
  color: #ccc;
}

/* Form Styling */
.contactuspg-form {
  background-color: #f9f9f9;
  border-radius: 10px;
}

.contactuspg-input {
  border: none;
  border-bottom: 2px solid #ccc;
  padding: 12px;
  border-radius: 0;
}

.contactuspg-input:focus {
  outline: none;
  border-bottom-color: #673ab7;
}

.contactuspg-form button {
  background-color: #673ab7;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

.contactuspg-form button:hover {
  background-color: #5e35b1;
}
