.home-gallery {
    max-width: 1500px;
    margin: 0 auto;
  }
  
  .gallery-item {
    padding: 5px;
  }
  
  .gallery-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  #pricing-tabs .nav-link {
    background-color: #333333;
    color: #fff;
    border-radius: 5px;
  }
  
  #pricing-tabs .nav-link.active {
    background-color: #3E1EC9;
    color: #fff;
  }
  

  /* home-gallery.css */

.home-gallery {
    padding: 20px;
  }
  
  #gallery-tabs .nav-item {
    margin-right: 15px; /* Add space between the tabs */
  }
  
  #gallery-tabs .nav-item:last-child {
    margin-right: 0; /* Ensure last tab doesn't get extra margin */
  }
  
  .gallery-item {
    margin-bottom: 20px; /* Add space between the images */
  }
  