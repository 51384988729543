.support-section {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
  }
  
  .support-col {
    display: flex;
    justify-content: flex-start; /* Align content to the left */
    align-items: center;
    margin: 10px 0;
  }
  
  .support-item {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}
  
.social-icons {
  place-content: center;
  margin-bottom: 14px;
}
  .support-icon {
    width: 20%;
    height: auto;
  }
  
  .support-text {
    text-align: left;
  }
  
  .support-heading {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
    text-decoration: none !important;
  }
  
  .support-detail {
    font-size: 27px;
    color: #ffffff; /* White text */
    font-weight: 700;
    text-decoration: none; /* Removes underline */
}

.support-detail:hover,
.support-detail:focus,
.support-detail:active {
    color: #ffffff; /* Ensures text remains white */
    text-decoration: none; /* Ensures no underline appears */
}

  
  .footer-row {
    margin-top: 20px;
  }
  
  .footer-col p {
    margin: 5px 0;
    font-size: 14px;
  }
  
  .footer-col a {
    color: #bbb;
    text-decoration: none;
    margin: 0 5px;
  }
  
  .footer-col a:hover {
    color: #fff;
  }
 
  .footer-row {
    margin-top: 20px;
  }
  
  .copyright-text p {
    margin: 5px 0;
    color: #ffffff;
    font-size: 16px; /* Set font size to 14px */
    
  }
  
  .terms-links p {
    margin: 5px 0;
    color: #ffffff;
    font-size: 16px; /* Set font size to 14px */
  }
  
  .terms-links a {
    color: #ffffff;
    text-decoration: none;
    margin: 0 5px;
    font-size: 16px; /* Set font size to 14px */
  }
  
  .terms-links a:hover {
    color: #fff;
  }


  .support-col a {
    text-decoration: none;
    color: inherit;
  }
  .support-col a:hover {
    text-decoration: none;
    color: inherit;
  }
  .support-col a:visited {
    text-decoration: none;
    color: inherit;
  }
  .support-col a.support-item {
    text-decoration: none !important;
    color: inherit !important;
  }
  
  /* Custom responsive styling */
  @media (max-width: 1300px) {
    .support-col {
      flex: 0 0 50%; /* Two columns per row */
      max-width: 50%;
    }
  }
  
  @media (max-width: 767px) {
    .support-col {
      flex: 0 0 100%; /* One column per row */
      max-width: 100%;
    }
    .support-item {
        display: flex
    ;
        align-items: center;
        gap: 10px;
        justify-content: start;
    }

  }
    .support-col a {
      text-decoration: none;
      color: inherit;
    }

    .support-col a:hover {
      text-decoration: none;
      color: inherit;
    }

    .support-col a:visited {
      text-decoration: none;
      color: inherit;
    }

    .support-col a.support-item {
      text-decoration: none !important;
      color: inherit !important;
    }
