.custom-section {
  position: relative;
  overflow: hidden;
  padding: 50px 0px;
}

.transition-row {
  display: flex;
  transform: translateX(0);
  transition: transform 1s ease-in-out; /* Smooth left-to-right sliding */
}

.transition-row.active {
  transform: translateX(-100%); /* Move the row to the left */
}

.content-row {
  display: flex;
  flex-shrink: 0;
  width: 100%;
}

.content-column,
.image-column {
  flex: 1;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.image-column img {
  width: 100%;
  height: auto;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 767px) {
  .content-row {
    flex-direction: column;
  }
}
.custom-section h3 {
  font-size: 31px;
  font-weight: 700;
  line-height: 1.1; /* Adjust line height for better readability */
  margin-bottom: 10px; /* Optional spacing */
}

.custom-section p {
  text-align: justify;
  font-size: 16px; /* Optional: adjust font size for readability */
  line-height: 1.6; /* Optional: adjust line spacing */
}

.transition-row.active {
  transition: opacity 1s ease, transform 1s ease; /* Optional smooth transition */
}
.main-heading {
  font-size: 48px;
  font-weight: 800;
 
  margin-top: 0px;
  color: #333;
}

/* Style for the main description */
.main-description {
  font-size: 20px !important;
  text-align: center;
  color: #000000;
  font-weight: 400;
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .main-heading {
    font-size: 38px;
    text-align: center;
    
  }
  .custom-section h3 {
    font-size: 28px;
  }
  .main-description {
    font-size: 16px !important;
    line-height: 1.1em;
    margin-bottom: 20px;
}

.custom-section {
  padding: 30px 0px;
}


}

